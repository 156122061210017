import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../user/user.service';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import moment from 'moment';
import { CommonServiceService } from '../common-service.service';
import lodash from 'lodash';
import { ApiHelperService, CloudFnNames } from '../apiHelperService/api-helper.service';
import { SlotStatus } from '../../constants/enums';
import { MessageService } from 'primeng/api';
export type EventHandler = (...args: any[]) => any;

@Injectable({
  providedIn: 'root'
})
export class EventService {

  loaderUploadingCount$ = new Subject();

  constructor(
    private user: UserService,
    private db: AngularFireDatabase,
    private commonFun: CommonServiceService,
    private apiHelperService: ApiHelperService,
    private messageService: MessageService
  ) { }  

  async getOrgs(requiredOrgPropsParam = []) {

    let requiredOrgProps;
    if (requiredOrgPropsParam.length > 0) {
      requiredOrgProps = requiredOrgPropsParam;
    } else {
      requiredOrgProps = [
        'key',
        'orgName',
        'orgLogo',
        'createdAt',
        'studioID',
        'type',
        'administratorName',
        'shippingCity',
        'shippingState',
        'shippingAddress',
        'shippingPostCode',
        'parentList.all',
        'syncSource',
        'lastAeriesImport',
        'lastBlackbaudImport',
        'lastOneRosterImport',
        'clientID',
        'clientSecret',
        'aeriesUrl',
        'schoolCode',
        'certificate',
        'districtID'
      ];
    }
    let payload = { 
      by: 'parentID',
      value: this.user.studioID,
      authDependencies: [
        { 
          forRule: "static",
          requires: "userAccountInfo"
        }
      ], 
      propsToReturn: requiredOrgProps 
    };
    
    let orgDataRes = await this.apiHelperService.postToCloudFn(CloudFnNames.getOrgs, payload);
    let orgsList: any[] = lodash.get(orgDataRes, "result.data");
    if (Array.isArray(orgsList) && orgsList.length) {
      orgsList.sort((a, b) => {
        if (b.createdAt < a.createdAt) {
          return -1;
        }
        if (b.createdAt > a.createdAt) {
          return 1;
        }
        return 0;
      });
    }
    return orgsList
  }

  /**
  * Get album of specific 
  * @param orgId string type
  * @param assetId string type
  * @returns DataSnapshot
  */
  async getAlbumData(orgId, assetId) {
    return await this.db.object(`portal/assets/${this.user.studioID}/${orgId}/${assetId}`).query.once('value')
  }
  
  /**
  * Get all albums of specific organization
  * @param orgId string type 
  * @returns DataSnapshot
  */
  async getAllAlbumData(orgId) {
    return await this.db.object(`portal/assets/${this.user.studioID}/${orgId}`).query.once('value')
  }

  async getAllEventsOfOrg(orgId) {
    // Currently get all cycle's event data... Will make it dynamic later.
    return await this.db.object(`portal/events/${this.user.studioID}/${orgId}`).query.once('value')
  }

  private async deleteAlbum(orgId, albumId) {
    return await this.db.object(`portal/assets/${this.user.studioID}/${orgId}/${albumId}/`).set(null)
  }

  async deleteImage(orgId, albumId, imgId, photoUrl) {
    if (photoUrl) this.commonFun.deleteFile(photoUrl)
    return await this.db.object(`portal/assets/${this.user.studioID}/${orgId}/${albumId}/content/${imgId}`).set(null)
  }

  setScheduleData(orgId, eventId, object) {
    return this.db.object(`portal/events/${this.user.studioID}/${orgId}/2024/${eventId}/schedule`).set(object)
  }

  updateAlbumData(path, object) {
    this.db.object(`${path}`).update(object);
  }

  setFirebaseData(path, object){
    this.db.object(`${path}`).set(object);
  }
  
  async updateFirebaseData(path, object){
    return this.db.object(`${path}`).update(object);
  }

  async sendToArchivedEvent(path, object) {
    return this.db.object(`portal/events-archived/${path}`).update(object);
  }
  
  getArchivedEvent() {
    return this.db.object(`portal/events-archived/${this.user.studioID}/`).valueChanges()
  }

  async getAssetsOfEvent(orgId, eventId) {
    return await this.db.object(`portal/events/${this.user.studioID}/${orgId}/2024/${eventId}/settings/assets`).query.once('value');
  }

  async updateAssetsOfEvent(orgId: string, eventId: string, assets: string[]) {
    await this.db.object(`portal/events/${this.user.studioID}/${orgId}/2024/${eventId}/settings/assets`).set(assets);
    await this.db.object(`portal/events/${this.user.studioID}/${orgId}/2024/${eventId}/meta/updatedAt`).set(moment().valueOf());
  }

  async getFirstEventFromGivenDate(date: number, studioId: string, orgId: string) {
    return await this.db.list(`/portal/events/${studioId}/${orgId}/2024/`, ref =>
      ref.orderByChild('meta/eventDate').startAt(date).limitToFirst(1)
    ).query.once('value');
  }

  async deleteAlbumFromAllSide(orgId: string, album: any) {
    // Remove album from S3
    let path = `portal/assets/${this.user.studioID}/${orgId}/${album.key}/`
    this.commonFun.deleteImageFolder(path).subscribe(() => {console.log('call delete')});

    // Remove album from firebase db
    let relatedEvents = lodash.get(album, 'meta.relatedEvent', []);
    if (!lodash.isEmpty(relatedEvents)) {
      let relatedEventPromise: any[] = [];
      relatedEvents.forEach((event: any) => {
        relatedEventPromise.push(this.getAssetsOfEvent(orgId, event.eventId));
      });
      let [resSnapshot, err]: any = await this.commonFun.executePromise(Promise.all(relatedEventPromise));
      let eventDataList: any[] = [];
      resSnapshot.forEach((snapshot) => {
        eventDataList.push(snapshot.val());
      });
      lodash.forEach(eventDataList, (eventAsset) => {
        let index = eventAsset?.findIndex(id => id == album.key);
        eventAsset = eventAsset?.splice(index, 1)
      })
      relatedEventPromise = [];
      lodash.forEach(relatedEvents, (event: any, i) => {
        relatedEventPromise.push(this.updateAssetsOfEvent(orgId, event.eventId, eventDataList[i]));
      });
    }
    
    return new Promise(async (resolve, reject) => {
      this.deleteAlbum(orgId, album.key)
      .then(() => resolve(true))
      .catch((err) => reject(err));
    })
  }

  getEventById(eventId, orgId, studioID?) {
    return this.db.object(`portal/events/${studioID ? studioID : this.user.studioID}/${orgId}/2024/${eventId}`).query.once('value');
  }

  getEventScheduleById(eventId, orgId, studioID?) {
    return this.db.object(`portal/events/${studioID ? studioID : this.user.studioID}/${orgId}/2024/${eventId}/schedule`).query.once('value');
  }

  getEventListener(eventId, orgId, studioId = null) {
    return this.db.object(`portal/events/${studioId || this.user.studioID}/${orgId}/2024/${eventId}`).valueChanges();
  }

  importSubjects(subjectList: string[], studioId, orgId, eventId) {
    return this.db.object(`/portal/events/${studioId}/${orgId}/2024/${eventId}/subjects`).set(subjectList);
  }
  
  getImportSubjects(eventData: any) {
    return this.db.list(`/portal/events/${eventData.event.studioId}/${eventData.event.orgId}/2024/${eventData.event.eventId}/subjects`).query.once('value');
  }
  
  getMetaDataFromEvent(studioId, orgId, eventId) {
    return this.db.list(`/portal/events/${studioId}/${orgId}/2024/${eventId}/meta`).query.once('value');
  }

  getSlotByIndId(studioId, orgId, eventId, indId) {
    return this.db.list(`/portal/events/${studioId}/${orgId}/2024/${eventId}/slots`).query.orderByChild("indID").equalTo(indId).once('value');
  }

  getSlotById(studioId, orgId, eventId, slotId) {
    return this.db.list(`/portal/events/${studioId}/${orgId}/2024/${eventId}/slots/${slotId}`).query.once('value');
  }

   getAllSlots(studioId, orgId, eventId) {
    return this.db.list(`/portal/events/${studioId}/${orgId}/2024/${eventId}/slots`).query.once('value');
  }

  async updateSlotData(studioId: string, orgId: string, eventId: string, slotId: string, indId: string, operation: SlotStatus, slotStartTimeString, sittingStatus = null) {
    if (!studioId || !orgId || !eventId || !slotId) {
      throw new Error("Params missing!");
    }
    try {
      await this.apiHelperService.postToCloudFn(
        CloudFnNames.updateSlot,
        {
          studioId,
          orgId,
          eventId,
          slotId,
          indId,
          operation,
          slotStartTimeString,
          sittingStatus
      }
    );
      return true;
    } catch (error) {
      throw error;
    }
  }

  async removeIndFromSlot(studioId: string, orgId: string, eventId: string, slotId: string) {
    const path = `/portal/events/${studioId}/${orgId}/2024/${eventId}/slots/${slotId}`;
    await this.db.object(path).update({
      indID: null,
      slotStartTimeString: null,
      status_appointment: null,
      status_sit: null
    });
  }

  private c = new Map<string, EventHandler[]>();

  subscribe(topic: any, ...handlers: EventHandler[]) {
    let topics = this.c.get(topic);
    if (!topics) {
      this.c.set(topic, (topics = []));
    }
    topics.push(...handlers);
  }

  unsubscribe(topic: string, handler?: EventHandler): boolean {
    if (!handler) {
      return this.c.delete(topic);
    }

    const topics = this.c.get(topic);
    if (!topics) {
      return false;
    }

    // We need to find and remove a specific handler
    const index = topics.indexOf(handler);

    if (index < 0) {
      // Wasn't found, wasn't removed
      return false;
    }
    topics.splice(index, 1);
    if (topics.length === 0) {
      this.c.delete(topic);
    }
    return true;
  }

  publish(topic: string, ...args: any[]): any[] | null {
    const topics = this.c.get(topic);
    if (!topics) {
      return null;
    }
    return topics.map(handler => {
      try {
        return handler(...args);
      } catch (e) {
        console.error(e);
        return null;
      }
    });
  }

  async getOrgAllEvents(orgID: string, studioID: string){
    if (!orgID || !studioID) throw new Error("params missing!");
    return (
      await this.db
        .list(`portal/events/${studioID}/${orgID}/2024`).query
        .once("value")
    ).val();
  }

  async createEvent(studioId: string, orgId: string, eventId: string, eventDataObj) {
    const eventPath = `/portal/events/${studioId}/${orgId}/2024/${eventId}`;
    try {
      await this.db.object(eventPath).set(eventDataObj);
    } catch (error) {
      throw error;
    }
  }

  async updateEvent(studioId: string, orgId: string, eventId: string, eventDataObj) {
    const eventPath = `/portal/events/${studioId}/${orgId}/2024/${eventId}`;
    try {
      await this.db.object(eventPath).update(eventDataObj);
    } catch (error) {
      throw error;
    }
  }
}
